<script>
import { getRandomCard } from "./SelectedCard.js";

export default {
  data() {
    return {
      SelectedCard: getRandomCard(),
      cardAngle: 0,
      display: { showImage: true, showBack: false },

      backText: { text: null, opacity: 1 },
    };
  },

  mounted() {
    this.setText();
  },

  methods: {
    doRotate: function () {
      //Rotates the image by 90 degrees
      let newAngle = parseInt(this.cardAngle) + 90;

      //Show back if completely rotated
      if (newAngle > 360) {
        this.display.showImage = false;
        this.display.showBack = true;

        //Change rotation
        newAngle = 0;
      }

      //Set the new card
      this.cardAngle = newAngle;

      //Set the text
      this.setText();
    },

    setText: function () {
      //Changes text based on card rotation

      var funcSetBackText = this.setBackText;
      var funcBackText = this.backText;

      //Goes through promise chain for timing
      Promise.resolve()
        .then(() => fadeTextOut())
        .then(() => delay(5000))
        .then(() => updateText())
        .then(() => delay(5000)) //Takes the card 5 sec to rotate
        .then(() => fadeTextIn());

      function delay(duration) {
        return new Promise((resolve) => {
          setTimeout(() => resolve(), duration);
        });
      }

      function fadeTextOut() {
        funcBackText.opacity = 0;
      }

      function updateText() {
        funcSetBackText();
      }

      function fadeTextIn() {
        console.log("Fade text in");
        funcBackText.opacity = 1;
      }
    },

    setBackText: function () {
      //Sets text on the back of the card
      switch (this.cardAngle) {
        case 0:
          this.backText.text = this.SelectedCard.back[0];
          break;

        case 90:
          this.backText.text = this.SelectedCard.back[1];
          break;

        case 180:
          this.backText.text = this.SelectedCard.back[2];
          break;

        case 270:
          this.backText.text = this.SelectedCard.back[3];
          break;

        case 360:
          this.backText.text = this.SelectedCard.question;
          break;

        default:
          this.backText.text = null;
          break;
      }
    },

    newCardSession: function () {
      //Gets a new card and restarts session
      this.SelectedCard = getRandomCard();
      this.cardAngle = 0;
      this.display = { showImage: true, showBack: false };
      //Set the text
      this.setText();
    },
  },
};
</script>

<template>
  <div class="Artcard">
    <div id="card_front" v-show="display.showImage === true">
      <div id="card_image">
        <div v-on:click="doRotate">
          <h2>{{ SelectedCard.name }}</h2>
          <img class="image_rotate" v-bind:src="SelectedCard.image" v-bind:style="'transform: rotate(' + cardAngle + 'deg)'" />
        </div>
      </div>

      <div id="card_text" v-bind:style="'opacity: ' + backText.opacity">
        {{ backText.text }}
      </div>
    </div>

    <div v-show="display.showBack === true">
      <div id="card_back">
        <div class="cardback_image">
          <img class="image_rotate" v-bind:src="SelectedCard.image" />
        </div>
        <div class="cardback_text">
          <div class="cardbackText_container">
            <h2>{{ SelectedCard.name }}</h2>
            <p v-for="item in SelectedCard.back">
              {{ item }}
            </p>
            <div>
              <h3>Guidance Question:</h3>
              {{ SelectedCard.question }}
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: center">
        <button v-on:click="newCardSession()">Another Card Session</button>
        <router-link to="/order" tag="button">More about Art Guidance Cards</router-link>
      </div>
    </div>
  </div>
</template>

<style>
.image_rotate {
  border: 1px solid #000000;
  transition-duration: 5s;
  cursor: pointer;
}

#card_text {
  transition: opacity 4s;
}
</style>
