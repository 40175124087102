/**
 * Functions used in app
 */

function NewlineText(text) {
  //Converts /n (new line) into array of paragraphs.
  var i = 0;
  const newText = text
    .toString()
    .split("\n")
    .map((str) => {
      i++;
      //return <p key={i}>{str}</p>;
      return str;
    });

  return newText;
}

//Format Dates
var FormatDate = Object.freeze({
  IsValid: function (dateVal) {
    //Checks to see if date is valid
    let isValid = Date.parse(dateVal);

    if (isNaN(isValid)) {
      return false;
    } else {
      return true;
    }
  },

  YYYYMMDD: function () {
    //Returns YYYY-MM-DD format.  May need input param objDate = new Date(), check for imported duds
    //Create Date
    var objDate = new Date();

    //Extract month etc.
    let year = objDate.getUTCFullYear();

    let month = objDate.getUTCMonth() + 1; //months '0' thing
    if (month < 10) {
      month = "0" + month;
    }

    let date = objDate.getUTCDate();
    if (date < 10) {
      date = "0" + date;
    }

    //Return formatted date
    return year + "-" + month + "-" + date;
  },

  YYYYMMDD2: function (getJSDate) {
    //Returns YYYY-MM-DD format.  May need input param objDate = new Date(), check for imported duds
    if (getJSDate === undefined || getJSDate === null) {
      return null;
    }
    //Create Date
    var objDate = new Date(getJSDate);

    //Extract month etc.
    let year = objDate.getUTCFullYear();

    let month = objDate.getUTCMonth() + 1; //months '0' thing
    if (month < 10) {
      month = "0" + month;
    }

    let date = objDate.getUTCDate();
    if (date < 10) {
      date = "0" + date;
    }

    //Return formatted date
    return year + "-" + month + "-" + date;
  },

  MMMDYYYY: function (getJSDate) {
    //Returns MMM D, YYYY format.  May need input param objDate = new Date(), check for imported duds
    if (getJSDate === undefined || getJSDate === null) {
      return null;
    }

    //Create Date
    var objDate = new Date(getJSDate);

    //Extract month etc.
    let year = objDate.getUTCFullYear();

    //Get month
    let arMonths = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "June", "July", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."];

    let month = arMonths[objDate.getUTCMonth()];

    let date = objDate.getUTCDate();

    //Return formatted date
    //MMM. D, YYYY
    return month + " " + date + ", " + year;
  },

  MMYYYY: function (getJSDate) {
    //Returns MM. DD format.  May need input param objDate = new Date(), check for imported duds
    if (getJSDate === undefined || getJSDate === null) {
      return null;
    }
    //Create Date
    var objDate = new Date(getJSDate);

    //Extract month etc.
    let year = objDate.getUTCFullYear();

    let month = objDate.getUTCMonth() + 1; //months '0' thing
    if (month < 10) {
      month = "0" + month;
    }

    //Return formatted date
    return month + ". " + year;
  },

  DayOfWeek: function (getJSDate) {
    //Returns YYYY-MM-DD format.  May need input param objDate = new Date(), check for imported duds

    if (getJSDate === undefined || getJSDate === null) {
      return null;
    }

    //Create Date
    var objDate = new Date(getJSDate);

    //Extract month etc.
    let day = objDate.getUTCDay();

    let arDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    //let date = objDate.getUTCDate();

    //Return formatted date
    return arDays[day];
  },

  DD: function (getJSDate) {
    //Returns YYYY-MM-DD format.  May need input param objDate = new Date(), check for imported duds

    if (getJSDate === undefined || getJSDate === null) {
      return null;
    }

    //Create Date
    var objDate = new Date(getJSDate);

    //Return formatted date
    return objDate.getUTCDate();
  },

  TimeAMPM: function (getJSDate) {
    //Returns HH:MM AMPM   format.  May need input param objDate = new Date(), check for imported duds

    if (getJSDate === undefined || getJSDate === null) {
      return null;
    }

    //Create Date
    var objDate = new Date(getJSDate);

    let ampm = "am";
    let hrs = objDate.getHours();
    let min = objDate.getMinutes();

    //Based on 24 hour clock
    if (hrs >= 12) {
      ampm = "pm";
    }

    //Switch to 12 hour clock
    if (hrs > 12) {
      hrs = hrs - 12;
    }

    if (min < 10) {
      min = "0" + min;
    }

    //Return formatted date
    return hrs + ":" + min + " " + ampm;
  },

  MMMDYYYY_Time: function (getJSDate) {
    //Returns MM D, YY HH:MM ampm
    if (getJSDate === undefined || getJSDate === null) {
      return null;
    }

    let strDate = FormatDate.MMMDYYYY(getJSDate);
    let strTime = FormatDate.TimeAMPM(getJSDate);

    return strDate + " " + strTime;
  },
});

export { NewlineText, FormatDate };
