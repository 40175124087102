import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import ArtCardApp from "../views/ArtCardApp.vue";
import OrderCards from "../views/OrderCards.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/artcard",
    name: "artcard",
    component: ArtCardApp,
  },

  {
    path: "/order",
    name: "order",
    component: OrderCards,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
