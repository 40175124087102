<script>
import ArtCard from "../components/ArtCard.vue";
//import "./ArtCard.css";
import "../views/ArtCard.css";

export default {
  name: "ArtCardApp",
  components: {
    ArtCard,
  },
};
</script>

<template>
  <ArtCard></ArtCard>
</template>
