<template>
  <div class="home">
    <h1>Welcome to Art Guidance Cards</h1>
    <div>Blurb about what happens goes here.</div>
    <div><router-link to="/artcard">Start Your Session</router-link></div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
};
</script>
