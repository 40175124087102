/**
 * Returns array of art cards to work with
 */

const arCard = [
  {
    id: 1,
    name: "Angel Light",
    image: "01AngelLight_1.jpg",
    back: "Let the angels be present in your life. \nFeel the core of your being with angel light. \nFloat with guardian angel guidance. \nAccept all possiblities.",
    question: "What will you do today to allow your angel light shine brightly?",
  },
  {
    id: 2,
    name: "Balance",
    image: "02BALANCE_1.jpg",
    back: "Breathe calmly. \nFind the center of your being. \nImagine yourself in perfect harmony with each breath. \nCreate perfect balance starting with your breath. \n",
    question: "What new thought or action will guide you to experience balance?",
  },
  {
    id: 3,
    name: "Conscious Surrender",
    image: "03CONSCIOUSSURRENDER_1.jpg",
    back: "Let go of the ego. \nLet go of the pushing. \nLet go of the struggle and fight. \nBecome conscious and surrender to what your spirits want you to know.",
    question: "When listening to your heart and soul what do you need surrendering to?",
  },
  {
    id: 4,
    name: "Core Feelings",
    image: "04COREFEELING_1.jpg",
    back: "Feel the emotions in the center of your being. \nLet yourself feel the emotions in the whole of your body. \nName and validate your feelings without judgement. \nBreathe in and out, just feel.",
    question: "Which feelings do you need to connect with right now?",
  },
  {
    id: 5,
    name: "Divine Spirit Connection",
    image: "05DEVINESPIRITCONNECTION_1.jpg",
    back: "Feel the lightness of being. \nBath in the golden light of spirit. \nInvite your guide to communicate. \nFeel the devine connection.",
    question: "What new possibilities inspire you to connect with spirit?",
  },
  {
    id: 6,
    name: "Dynamic Manifestation",
    image: "06DYNAMICMANIFESTATION_1.jpg",
    back: "Make a wish. \nOpen the door of possibility. \nAllow the power of your desire to manifest your wish. \nVisualize your wish comming true.",
    question: "What life long dreams do you want to manifest?",
  },
];

export{arCard};
