/**
 * Returns object with selected card
 */

import { NewlineText } from "../functions.js";
import { arCard } from "../../public/cards/cards_data.js";

const filePath = "cards/cardimages/";

function getSelectedCard(cardID) {
  //Returns card obj from cardID ()
  let objCard = arCard.find((card) => card.id === cardID);

  //Parse the data
  let Card = parseCard(objCard);

  //Return card object
  return Card;
}

function parseCard(objCard) {
  //Returns card copy  with data formated

  //Make new copy
  const copyCard = { ...objCard };

  //Parse the data
  copyCard.image = filePath + copyCard.image;
  copyCard.back = NewlineText(copyCard.back);

  //Return card object
  return copyCard;
}

function getRandomCard() {
  //Returns a random card from array
  let listLength = arCard.length;
  let cardNumber = Math.floor(Math.random() * listLength);
  let objCard = arCard[cardNumber];
  objCard = parseCard(objCard);

  return objCard;
}

export { getRandomCard, getSelectedCard };
